import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { ModalWindow } from "../UI/ModalWindow/ModalWindow";

import { deleteTask } from "../../store/actions/tasksActions";

import classes from "./TasksTable.module.scss";
import { TasksRow } from "./TasksRow";

let timerId;

export const TasksTable = ({
  tasks,
  projectId,
  groupId,
  onEdit,
  folderDepth,
  allTasks
}) => {
  const dispatch = useDispatch();

  const [deletedId, setDeletedId] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [activeTask, setActiveTask] = React.useState(null);

  const countEditHovered = React.useRef();
  countEditHovered.current = 0;

  const countDeleteHovered = React.useRef();
  countDeleteHovered.current = 0;

  const countCollapseHovered = React.useRef();
  countCollapseHovered.current = 0;

  const countUnlinkHovered = React.useRef();
  countUnlinkHovered.current = 0;

  const countAddNextHovered = React.useRef();
  countAddNextHovered.current = 0;

  const countAddPrevHovered = React.useRef();
  countAddPrevHovered.current = 0;

  const onDelete = (id) => {
    setDeletedId(id);
    setOpenDeleteModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteTask(deletedId, projectId));
    setOpenDeleteModal(false);
  };

  const refs = tasks.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleShowLinkedTask = (id) => {
    setActiveTask(id);

    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });

    timerId = setTimeout(() => {
      setActiveTask(null);
    }, 1500);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [openTask, setOpenTask] = React.useState(null);

  const [showAddPrevTask, setShowAddPrevTask] = React.useState(false);
  const [showAddNextTask, setShowAddNextTask] = React.useState(false);

  const toggleCollapseTasks = (id) => {
    if (openTask === id) {
      setOpenTask(null);

      setShowAddPrevTask(false);
      setShowAddNextTask(false);
    } else {
      setOpenTask(id);
    }
  };

  return (
    <>
      <div className={classes.table_wrapper}>
        <div className={classes.table}>
          <div className={classes.fixed}>
            <div
              className={classes.tableHeader}
              style={{ paddingLeft: folderDepth * 35 + 30 + "px" }}
            >
              <div className={`${classes.headerItem} ${classes.name}`}>
                Название задачи
              </div>
              <div className={classes.headerItem}>Сотрудник</div>
              <div className={classes.headerItem}>
                Начало <span className={classes.lowercase}>(прогноз)</span>
              </div>
              <div className={classes.headerItem}>
                Конец<span className={classes.lowercase}>(прогноз)</span>
              </div>
              <div className={classes.headerItem}>
                Конец<span className={classes.lowercase}> (факт)</span>
              </div>
              <div className={classes.headerItem}></div>
            </div>
            <div className={classes.tableBody}>
              {tasks.map((row, index) => (
                <TasksRow
                  key={index}
                  row={row}
                  index={index}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  tasks={tasks}
                  projectId={projectId}
                  countEditHovered={countEditHovered}
                  countDeleteHovered={countDeleteHovered}
                  countCollapseHovered={countCollapseHovered}
                  countUnlinkHovered={countUnlinkHovered}
                  countAddPrevHovered={countAddPrevHovered}
                  countAddNextHovered={countAddNextHovered}
                  handleShowLinkedTask={handleShowLinkedTask}
                  refs={refs}
                  activeTask={activeTask}
                  openTask={openTask}
                  folderDepth={folderDepth}
                  allTasks={allTasks}
                  toggleCollapseTasks={toggleCollapseTasks}
                  showAddPrevTask={showAddPrevTask}
                  setShowAddPrevTask={setShowAddPrevTask}
                  showAddNextTask={showAddNextTask}
                  setShowAddNextTask={setShowAddNextTask}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title={"Удалить задачу"}
      >
        <p className={classes.block_info}>
          Вы уверены, что хотите удалить задачу?
        </p>

        <div className={classes.button_wrapper}>
          <Button
            classes={{
              root: classes.cancel_btn
            }}
            onClick={() => setOpenDeleteModal(false)}
            variant="contained"
            type="submit"
            disableElevation
          >
            Отменить
          </Button>
          <Button
            classes={{
              root: classes.submit_btn
            }}
            onClick={handleDelete}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Удалить
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};
