import * as React from "react";

const SvgAddGroup = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.75 4.75H10.75L9 3H3.75C2.77875 3 2.00875 3.77875 2.00875 4.75L2 15.25C2 16.2212 2.77875 17 3.75 17H17.75C18.7213 17 19.5 16.2212 19.5 15.25V6.5C19.5 5.52875 18.7213 4.75 17.75 4.75ZM16.875 11.75H14.25V14.375H12.5V11.75H9.875V10H12.5V7.375H14.25V10H16.875V11.75Z"
      fill="#5E6368"
    />
  </svg>
);

export default SvgAddGroup;
