import * as React from "react";

const SvgCopyIcon = (props) => (
  <svg
    width={21}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 1.375H3.5c-.962 0-1.75.788-1.75 1.75v10.5H3.5v-10.5H14v-1.75Zm2.625 3.5H7c-.963 0-1.75.787-1.75 1.75v10.281c0 .963.787 1.75 1.75 1.75h9.625c.962 0 1.75-.787 1.75-1.75V6.625c0-.963-.788-1.75-1.75-1.75Zm0 12.031H7V6.625h9.625v10.281Z"
      fill="#5E6368"
    />
  </svg>
);

export default SvgCopyIcon;
