import * as types from "../types";

const initialState = {
  groups: null
};

export function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GROUPS:
      return {
        ...state,
        groups: action.payload
      };

    default:
      return state;
  }
}
