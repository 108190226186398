import axios from "axios";
import * as types from "../types";
import { fetchGroups } from "./groupsActions";
import { fetchProject } from "./projectsActions";

export const fetchTasks = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/tasks", { params: { project_id: id } })
      .then((response) => {
        dispatch({ type: types.FETCH_TASKS, payload: response.data });
      })
      .then(() => {
        dispatch(fetchProject(id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createTask = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/tasks", data)
      .then((response) => {
        dispatch(fetchGroups(data.project_id));
        dispatch(fetchTasks(data.project_id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const reassignTasks = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/tasks/reassign", data)
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const updateTask = (id, data, project_id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .patch(`/tasks/${id}`, data)
      .then((response) => {
        dispatch(fetchGroups(project_id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const deleteTask = (id, projectId) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/tasks/${id}`)
      .then((response) => {
        dispatch(fetchGroups(projectId));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const addTaskRelation = ({ id, relationId, type, projectId }) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post(`/tasks/${id}/relation`, {
        task_type: type,
        related_task_id: relationId
      })
      .then((response) => {
        dispatch(fetchGroups(projectId));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const removeTaskRelation = ({ id, relationId, projectId }) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/tasks/${id}/relation`, {
        params: { task_id: id, related_task_id: relationId }
      })
      .then((response) => {
        dispatch(fetchGroups(projectId));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};
