import * as React from "react";

const SvgDownload = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.833 8v5.833H2.167V8H.5v5.833c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V8h-1.667Zm-5 .558 2.159-2.15 1.175 1.175L8 11.75 3.833 7.583l1.175-1.175 2.159 2.15V.5h1.666v8.058Z"
      fill="#4CAF50"
    />
  </svg>
);

export default SvgDownload;
