import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { ConstructionControlProjectsRow } from "./ConstructionControlProjectsRow";
import classes from "./ConstructionControlProjectsTable.module.scss";

export const ConstructionControlProjectsTable = ({ cc_projects, handleEdit, handleDelete }) => {
  const dispatch = useDispatch();

  const onEdit = (id, value) => {
    handleEdit(id);
  };

  const onDelete = (id, value) => {
    handleDelete(id);
  };

  return (
    <>
      <div className={classes.table_wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Номер</TableCell>
              <TableCell align="left">Название проекта</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cc_projects.map((row, index) => (
              <ConstructionControlProjectsRow
                key={index}
                row={row}
                index={index}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
