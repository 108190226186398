import { Button, Icon } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CreateGroupModal } from "../../components/CreateGroupModal";

import MainContainer from "../../components/MainContainer/MainContainer";
import { TasksForm } from "../../components/TasksForm";
import { GroupsRow } from "../../components/Groups";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";
import { downloadReport } from "../../store/actions/projectsActions";
import {
  createTask,
  fetchTasks,
  updateTask
} from "../../store/actions/tasksActions";

import classes from "./Tasks.module.scss";
import {
  createGroup,
  deleteGroup,
  fetchGroups,
  updateGroup
} from "../../store/actions/groupsActions";
import axios from "axios";
import { DownloadIcon } from "../../components/UI/Svgs";

export const Tasks = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();

  const { groups } = useSelector(({ groups }) => groups);
  const { tasks } = useSelector(({ tasks }) => tasks);

  const { currentProject } = useSelector(({ projects }) => projects);

  // const [tasks, setTasks] = React.useState({});

  const folderDepth = 0;

  const [editedId, setEditedId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const [editedGroupId, setEditedGroupId] = React.useState(null);

  const [deletedId, setDeletedId] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const [openCreateGroupModal, setOpenCreateGroupModal] = React.useState(false);
  const [supergroupId, setSupergroupId] = React.useState(null);

  const onEditTask = (id) => {
    setEditedId(id);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditedId(null);
  };

  const handleOpenModal = (groupId) => {
    setOpenModal(true);
    setSupergroupId(groupId);
  };

  const onEditGroup = (id) => {
    setEditedGroupId(id);
    handleOpenCreateGroupModal();
  };

  const handleSubmit = ({ date, ...rest }) => {
    let start;

    if (typeof date === "string") {
      start = new Date(date);
    } else {
      start = date;
    }

    start?.setTime(start.setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000);
    start = start?.toISOString().split("T")[0];

    if (!editedId) {
      dispatch(
        createTask({
          ...rest,
          start: start ?? null,
          project_id: id,
          group_id: supergroupId
        })
      );
    } else {
      dispatch(
        updateTask(
          editedId,
          {
            ...rest,
            start: start ?? null
          },
          id
        )
      );
    }

    handleCloseModal();
  };

  const handleDownloadReport = () => {
    dispatch(downloadReport(id));
  };

  React.useEffect(() => {
    dispatch(fetchGroups(id));
    dispatch(fetchTasks(id));
  }, []);

  const handleCloseCreateGroupModal = () => {
    setOpenCreateGroupModal(false);
    setEditedGroupId(null);
  };

  const handleOpenCreateGroupModal = (id) => {
    setOpenCreateGroupModal(true);
    setSupergroupId(id);
  };

  const handleCreateGroup = ({ name }) => {
    if (editedGroupId) {
      dispatch(updateGroup(editedGroupId, { name, project_id: id }));
    } else {
      dispatch(createGroup({ name, project_id: id, supergroup: supergroupId }));
    }
    handleCloseCreateGroupModal();
  };

  const onDeleteGroupClick = (id) => {
    setOpenDeleteModal(true);
    setDeletedId(id);
  };

  const handleDeleteGroupClick = () => {
    dispatch(deleteGroup(deletedId, id));
    setOpenDeleteModal(false);
  };

  return (
    <>
      <MainContainer>
        <div className={classes.tasks}>
          <div className={classes.add_wrapper}>
            <Button
              variant="contained"
              classes={{
                root: classes.add_btn
              }}
              onClick={() => handleOpenCreateGroupModal(null)}
            >
              <span className={classes.btn_text}>Добавить запись</span>
            </Button>
          </div>
          {groups?.length > 0 ? (
            <section className={classes.tableWrapper}>
              <div className={classes.header}>
                <div className={classes.title}>
                  <Button
                    onClick={() => navigate("/projects")}
                    classes={{
                      root: classes.back_btn
                    }}
                  >
                    <img src="/assets/back.svg" alt="" srcset="" />
                  </Button>
                  {currentProject?.name || ""}
                </div>
                <div className={classes.report_btn_wrapper}>
                  <Button
                    variant="contained"
                    classes={{
                      root: classes.report_btn
                    }}
                    onClick={handleDownloadReport}
                    endIcon={<DownloadIcon />}
                  >
                    <span className={classes.btn_text}>Выгрузить</span>
                  </Button>
                </div>
              </div>
              <div className={classes.breadcrumbs}>
                <span
                  className={classes.breadcrumbsLink}
                  onClick={() => navigate("/projects")}
                >
                  Проекты
                </span>{" "}
                /{" "}
                {currentProject?.name.length < 50
                  ? currentProject.name
                  : currentProject?.name.slice(0, 50) + "..."}{" "}
                / группы
              </div>
              <div className={classes.table}>
                {groups.map((group, index) => (
                  <GroupsRow
                    onDelete={onDeleteGroupClick}
                    row={group}
                    index={index}
                    createGroup={handleOpenCreateGroupModal}
                    createTask={handleOpenModal}
                    projectId={id}
                    onEditTask={onEditTask}
                    onEditGroup={onEditGroup}
                    folderDepth={folderDepth}
                    tasks={tasks}
                  />
                ))}
              </div>

              {/* <TasksTable tasks={tasks}  /> */}
            </section>
          ) : (
            <div className={classes.no_rows}>
              <div className={classes.text}>
                Чтобы создать группу, нажмите кнопку “Добавить запись”
              </div>
            </div>
          )}
        </div>
      </MainContainer>
      <ModalWindow
        isOpen={openModal}
        handleClose={handleCloseModal}
        title={editedId ? "Изменить задачу" : "Создать задачу"}
      >
        <TasksForm
          onCancel={handleCloseModal}
          onSubmit={handleSubmit}
          editedId={editedId}
        />
      </ModalWindow>
      {openCreateGroupModal && (
        <CreateGroupModal
          openModal={openCreateGroupModal}
          handleCloseModal={handleCloseCreateGroupModal}
          onSubmit={handleCreateGroup}
          editedId={editedGroupId}
        />
      )}

      <ModalWindow
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title={"Удалить группу"}
      >
        <p className={classes.block_info}>
          Вы уверены, что хотите удалить группу?
        </p>
        <div className={classes.button_wrapper}>
          <Button
            classes={{
              root: classes.cancel_btn
            }}
            onClick={() => setOpenDeleteModal(false)}
            variant="contained"
            disableElevation
          >
            Отменить
          </Button>
          <Button
            classes={{
              root: classes.submit_btn
            }}
            onClick={handleDeleteGroupClick}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Удалить
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};
