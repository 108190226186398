import React from "react";
import { useHover } from "../../../utils/hooks/useHover";
import { Popup } from "../../UI/Popup/Popup";

import {
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowUpPlusIcon,
  ArrowDownPlusIcon
} from "../../UI/Svgs";

import classes from "./TasksRow.module.scss";

export const CurrentTask = ({
  row,
  setShowAddPrevTask,
  setShowAddNextTask,
  countAddPrevHovered,
  countAddNextHovered,
  folderDepth
}) => {
  const [hoverAddPrevRef, isAddPrevHovered] = useHover(countAddPrevHovered);
  const [hoverAddNextRef, isAddNextHovered] = useHover(countAddNextHovered);

  return (
    <div
      className={classes.currentTask}
      style={{ paddingLeft: folderDepth * 35 + 30 + "px" }}
    >
      <div className={classes.add}>
        <div className={classes.buttonWrapper}>
          <button
            onClick={() => setShowAddPrevTask(true)}
            ref={hoverAddPrevRef}
            className={`${isAddPrevHovered ? classes.hoveredGreen : ""} ${
              classes.addPrevBtn
            }`}
          >
            <div className={classes.icon}>
              {isAddPrevHovered ? <ArrowUpPlusIcon /> : <ArrowUpIcon />}
            </div>
            {isAddPrevHovered && countAddPrevHovered.current < 3 && (
              <div className={classes.popup}>
                <Popup text="Добавить предыдущую задачу" arrow />
              </div>
            )}
          </button>
          <button
            onClick={() => setShowAddNextTask(true)}
            ref={hoverAddNextRef}
            className={`${isAddNextHovered ? classes.hoveredGreen : ""} ${
              classes.addNextBtn
            }`}
          >
            <div className={classes.icon}>
              {isAddNextHovered ? <ArrowDownPlusIcon /> : <ArrowDownIcon />}
            </div>
            {isAddNextHovered && countAddNextHovered.current < 3 && (
              <div className={classes.popup}>
                <Popup text="Добавить последующую задачу" arrow />
              </div>
            )}
          </button>
        </div>
        {row.name}
      </div>
      <div>
        {row.employee?.name.length > 49
          ? row.employee?.name.split(0, 47) + "..."
          : row.employee?.name || "Нет исполнителя"}
      </div>
      <div>
        {row.dynamic_start ? new Date(row.dynamic_start).toLocaleDateString("ru-RU") : ""}
      </div>
      <div>
        {row.dynamic_end
          ? new Date(row.dynamic_end).toLocaleDateString("ru-RU")
          : ""}
      </div>
      <div>
        {row.actual_end
          ? new Date(row.actual_end).toLocaleDateString("ru-RU")
          : ""}
      </div>
    </div>
  );
};
