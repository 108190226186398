import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { ContractorsRow } from "./ContractorsRow";

import classes from "./ContractorsTable.module.scss";

export const ContractorsTable = ({ contractors, handleEdit, handleDelete }) => {
  const dispatch = useDispatch();
  const [contractorId, setContractorId] = React.useState(null);

  const onEdit = (id, value) => {
    handleEdit(id);
  };

  const onDelete = (id, value) => {
    handleDelete(id);
  };

  return (
    <>
      <div className={classes.table_wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Номер</TableCell>
              <TableCell align="left">Подрядчик</TableCell>
              <TableCell align="left">Проект</TableCell>
              <TableCell align="left">Дата выхода на объект</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractors.map((row, index) => (
              <ContractorsRow
                key={index}
                row={row}
                index={index}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
