import { Button } from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MainContainer from "../../components/MainContainer/MainContainer";
import { ContractorForm } from "../../components/ContractorForm";
import { ContractorsTable } from "../../components/ContractorsTable";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";
import {
  createContractor,
  deleteContractor,
  fetchContractors,
  updateContractor,
  downloadContractorsReport
} from "../../store/actions/contractorsActions";
import { fetchProjects } from "../../store/actions/projectsActions";

import classes from "./Contractors.module.scss";

export const Contractors = () => {
  const dispatch = useDispatch();
  const { contractors } = useSelector(({ contractors }) => contractors);

  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [editedId, setEditedId] = React.useState(null);
  const [deletedId, setDeletedId] = React.useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditedId(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmit = ({ title, project_id, type_of_work, contract_amount, teo, responsible_employee_id, labor_protection_specialist_employee_id, date }) => {
    let work_start_date;

    if (typeof date === "string") {
      work_start_date = new Date(date);
    } else {
      work_start_date = date;
    }

    work_start_date.setTime(work_start_date.setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000);
    work_start_date = work_start_date.toISOString().split("T")[0];

    if (!editedId) {
      dispatch(createContractor({ title, project_id, type_of_work, contract_amount, teo, responsible_employee_id, labor_protection_specialist_employee_id, work_start_date }));
    } else {
      dispatch(updateContractor(editedId, { title, project_id, type_of_work, contract_amount, teo, responsible_employee_id, labor_protection_specialist_employee_id, work_start_date }));
    }

    handleCloseModal();
  };

  const handleEdit = (id) => {
    setEditedId(id);
    handleOpenModal();
  };

  const handleOnClick = () => {
    dispatch(downloadContractorsReport());
  };

  const handleDelete = (id) => {
    setDeletedId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteContractor = () => {
    dispatch(deleteContractor(deletedId));
    setOpenDeleteModal(false);
  };

  React.useEffect(() => {
    dispatch(fetchContractors());
    dispatch(fetchProjects());
  }, []);

  return (
    <>
      <MainContainer>
        <div className={classes.projects}>
          <div className={classes.buttons_wrapper}>
            <div className={classes.add_wrapper}>
              <Button
                variant="contained"
                classes={{
                  root: classes.add_btn
                }}
                onClick={handleOpenModal}
              >
                <span className={classes.btn_text}>Добавить</span>
              </Button>
            </div>
            <div className={classes.export_wrapper}>
              <Button
                variant="contained"
                classes={{
                  root: classes.export_btn
                }}
                onClick={handleOnClick}
              >
                <span className={classes.btn_text}>Выгрузка</span>
              </Button>
            </div>
          </div>
          {contractors?.length > 0 ? (
            <section className={classes.tableWrapper}>
              <div className={classes.title}>Подрядчики</div>
              <ContractorsTable
                contractors={contractors}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </section>
          ) : (
            <div className={classes.no_rows}>
              <div className={classes.text}>
                Чтобы добавить подрядчика, нажмите кнопку “Добавить”
              </div>
            </div>
          )}
        </div>
      </MainContainer>
      <ModalWindow
        isOpen={openModal}
        handleClose={handleCloseModal}
        title={editedId ? "Изменить подрядчика" : "Создать подрядчика"}
      >
        <ContractorForm
          onCancel={handleCloseModal}
          onSubmit={handleSubmit}
          editedId={editedId}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title={"Удалить подрядчика"}
      >
        <p className={classes.block_info}>
          Вы уверены, что хотите удалить подрядчика?
        </p>
        <div className={classes.button_wrapper}>
          <Button
            classes={{
              root: classes.cancel_btn
            }}
            onClick={() => setOpenDeleteModal(false)}
            variant="contained"
            disableElevation
          >
            Отменить
          </Button>
          <Button
            classes={{
              root: classes.submit_btn
            }}
            onClick={handleDeleteContractor}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Удалить
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};
