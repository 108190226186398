import * as types from "../types";

const initialState = {
  projects: null,
  currentProject: null
};

export function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PROJECTS:
      return {
        ...state,
        projects: action.payload
      };
    case types.FETCH_PROJECT:
      return {
        ...state,
        currentProject: action.payload
      };

    default:
      return state;
  }
}
