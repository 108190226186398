import React from "react";
import {
  TableCell,
  TableRow,
  Switch,
  Button,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import classes from "./UsersRow.module.scss";
import { Link, NavLink } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import { CopyIcon } from "../../UI/Svgs";
import { Popover } from "../../UI/Popover/Popover";

let timerId;

const CustomSwitch = withStyles({
  root: {
    marginLeft: "-9px"
  },
  switchBase: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff"
    },
    "&$checked + $track": {
      backgroundColor: "#F36E21",
      opacity: "0.8 !important"
    }
  },
  checked: {},
  track: {}
})(Switch);

export const UsersRow = ({
  row,
  index,
  changeActivation,
  resendInvitation,
  onEdit
}) => {
  const copyLinkIconRef = React.useRef();

  const copyLinkIconCoords = copyLinkIconRef.current?.getBoundingClientRect();

  const [copiedLink, setCopiedLink] = React.useState(false);

  const copyLink = (link) => {
    // clearTimeout(timerId);

    navigator.clipboard.writeText(link);

    setCopiedLink(true);
    timerId = setTimeout(() => {
      setCopiedLink(false);
    }, 1000);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(row.id);
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      <TableRow className={classes.userRow}>
        <TableCell className={classes.gray}>{index + 1}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell className={classes.gray} align="left">
          {row.email}
        </TableCell>
        <TableCell className={classes.link} align="left">
          <div className={classes.linkInner}>
            <IconButton
              aria-label="expand row"
              size="small"
              className={classes.linkIcon}
              onClick={() => copyLink(row.invite_link)}
              ref={copyLinkIconRef}
            >
              <CopyIcon />
            </IconButton>
            <a href={row.invite_link} target="_blank" rel="noopener noreferrer">
              Перейти
            </a>
          </div>
        </TableCell>
        <TableCell align="left">
          <CustomSwitch
            checked={!row.is_blocked && row.access_activated_at}
            onChange={() => changeActivation(row.id, !row.is_blocked)}
            name="checkedB"
            color="primary"
            disabled={!row.access_activated_at}
          />
        </TableCell>
        <TableCell align="left">
          <Button
            className={classes.resendBtn}
            onClick={() => resendInvitation(row.id)}
          >
            Пригласить
          </Button>
        </TableCell>
        <TableCell align="left">
          <Button
              onClick={handleEdit}
              classes={{
                root: classes.btn
              }}
          >
            <CreateIcon />
          </Button>
        </TableCell>
      </TableRow>
      {copiedLink && (
        <Popover
          text={"Ссылка скопирована"}
          coords={{
            top: copyLinkIconCoords.top,
            left: copyLinkIconCoords.left
          }}
          addedStyle={{
            transform: `translate(calc(-50% + 12px), calc(-100% - 10px))`
          }}
          arrow
        />
      )}
    </>
  );
};
