import is from "is_js";

export const required = (value) => {
  if (value) return undefined;
  return "Обязательное поле";
};

export const email = (value) => {
  if (is.email(value)) return undefined;
  return "Введите почту";
};
