import * as React from "react";

const SvgDeleteIcon = (props) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 16.625c0 .962.787 1.75 1.75 1.75h7c.963 0 1.75-.788 1.75-1.75v-10.5H5.25v10.5Zm2.152-6.23 1.234-1.234 1.864 1.855 1.855-1.855 1.234 1.234-1.855 1.855 1.855 1.855-1.234 1.234-1.855-1.855-1.855 1.855-1.234-1.234 1.855-1.855-1.864-1.855Zm6.16-6.895-.874-.875H8.311l-.874.875H4.374v1.75h12.25V3.5h-3.063Z"
      fill="#5E6368"
    />
  </svg>
);

export default SvgDeleteIcon;
