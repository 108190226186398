import * as types from "../types";

const initialState = {
  users: []
};

export function usersReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_USERS:
      return {
        ...state,
        users: action.payload
      };
    default:
      return state;
  }
}
