import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f36e21"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#f36e21",
        color: "#fff"
      }
    }
  }
});

const helperTextStyles = makeStyles((theme) => ({
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      marginLeft: "0px"
    }
  }
}));

export const CustomDateInput = ({ placeholder, input }) => {
  const helperTestClasses = helperTextStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <ThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          {...input}
          placeholder={placeholder}
          inputVariant="outlined"
          format="dd.MM.yyyy"
          invalidDateMessage={"Неверная дата"}
          FormHelperTextProps={{ classes: helperTestClasses }}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          fullWidth
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
