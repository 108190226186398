import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, LinearProgress } from "@material-ui/core";
import classNames from "classnames/bind";

import { logout } from "../../store/actions/loginActions";

import { getWithExpiry } from "../../utils/localStorageWithExpiry";

import classes from "./MainContainer.module.scss";
const cx = classNames.bind(classes);

const MainContainer = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const username = getWithExpiry("username");

  const { loading } = useSelector(({ login }) => login);

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/", { replace: true });
  };

  if (username === "admin") {
    return (
      <div className={classes.MainContainer}>
        <header className={classes.header}>
          <div className={classes.appbar}>
            <div className={classes.logo}>
              <img
                src="/assets/logo.svg"
                alt="КамаСтройИнвест"
                width="200"
                height="40"
              />
            </div>
            <div className={classes.logout} onClick={logoutHandler}>
              <span className={classes.btn_text}>Выйти</span>
            </div>
          </div>
          <div className={classes.subheader}>
            <nav className={classes.nav}>
              <ul className={classes.navList}>
                <li className={classes.navItem}>
                  <Link
                    to="/"
                    className={cx({
                      link: true,
                      active: location.pathname === "/"
                    })}
                  >
                    <Button>Сотрудники</Button>
                  </Link>
                </li>
                <li className={classes.navItem}>
                  <Link
                    to="/projects"
                    className={cx({
                      link: true,
                      active: location.pathname.split("/")[1] === "projects"
                    })}
                  >
                    <Button>Проекты</Button>
                  </Link>
                </li>
                <li className={classes.navItem}>
                  <Link
                    to="/construction_control"
                    className={cx({
                      link: true,
                      active: location.pathname.split("/")[1] === "construction_control"
                    })}
                  >
                    <Button>Стройконтроль</Button>
                  </Link>
                </li>
                <li className={classes.navItem}>
                  <Link
                    to="/contractors"
                    className={cx({
                      link: true,
                      active: location.pathname.split("/")[1] === "contractors"
                    })}
                  >
                    <Button>Подрядчики</Button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={classes.loader}>{loading && <LinearProgress />}</div>
        <main className={classes.main}>
          <div className={classes.container}>{children}</div>
        </main>
      </div>
    );
  } else {
    return (
      <div className={classes.MainContainer}>
        <header className={classes.header}>
          <div className={classes.appbar}>
            <div className={classes.logo}>
              <img
                src="/assets/logo.svg"
                alt="КамаСтройИнвест"
                width="200"
                height="40"
              />
            </div>
            <div className={classes.logout} onClick={logoutHandler}>
              <span className={classes.btn_text}>Выйти</span>
            </div>
          </div>
          <div className={classes.subheader}>
            <nav className={classes.nav}>
              <ul className={classes.navList}>
                <li className={classes.navItem}>
                  <Link
                    to="/"
                    className={cx({
                      link: true,
                      active: location.pathname === "/"
                    })}
                  >
                    <Button>Стройконтроль</Button>
                  </Link>
                </li>
                <li className={classes.navItem}>
                  <Link
                    to="/contractors"
                    className={cx({
                      link: true,
                      active: location.pathname.split("/")[1] === "contractors"
                    })}
                  >
                    <Button>Подрядчики</Button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={classes.loader}>{loading && <LinearProgress />}</div>
        <main className={classes.main}>
          <div className={classes.container}>{children}</div>
        </main>
      </div>
    );
  }
};

export default MainContainer;
