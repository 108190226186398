import React from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import classNames from "classnames/bind";

import { TasksTable } from "../TasksTable";
import { AddGroupIcon, AddTaskIcon, DeleteIcon, FolderIcon } from "../UI/Svgs";
import { Popup } from "../UI/Popup/Popup";

import classes from "./GroupsRow.module.scss";
import useClickPreventionOnDoubleClick from "../../utils/hooks/useClickPreventionOnDoubleClick";
import { useHover } from "../../utils/hooks/useHover";
import { Popover } from "../UI/Popover/Popover";

const cx = classNames.bind(classes);

export const GroupsRow = ({
  row,
  index,
  onDelete,
  createGroup,
  createTask,
  projectId,
  onEditTask,
  onEditGroup,
  folderDepth,
  tasks
}) => {
  const templocalFolderDepth =
    JSON.parse(localStorage.getItem("folderDepth")) || {};

  const [openMore, setOpenMore] = React.useState(false);
  const [open, setOpen] = React.useState(templocalFolderDepth[index]);

  const [dropdown, setDropdown] = React.useState(false);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = (event) => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const onMoreItemClick = (event, type) => {
    event.stopPropagation();

    if (type === "group") {
      createGroup(row.id);
    } else {
      createTask(row.id);
    }
    setOpenMore(false);
  };

  const onClick = async (e) => {
    setOpen(!open);

    const obj = JSON.parse(localStorage.getItem("folderDepth"));

    const tempObj = { ...obj, [index]: !open };

    await localStorage.setItem("folderDepth", JSON.stringify(tempObj));
  };

  const onDoubleClick = () => {
    onEditGroup(row.id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEditGroup(row.id);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    onDelete(row.id);
  };

  const handleOpenMore = (e) => {
    e.stopPropagation();
    setOpenMore(!openMore);
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    onClick,
    onDoubleClick
  );

  const [hoverEditRef, isEditHovered] = useHover();
  const editIconCoords = hoverEditRef.current?.getBoundingClientRect();

  const [hoverDeleteRef, isDeleteHovered] = useHover();
  const deleteIconCoords = hoverDeleteRef.current?.getBoundingClientRect();

  const [hoverAddGroupRef, isAddGroupHovered] = useHover();
  const addGroupIconCoords = hoverAddGroupRef.current?.getBoundingClientRect();

  const [hoverAddTaskRef, isAddTaskHovered] = useHover();
  const addTaskIconCoords = hoverAddTaskRef.current?.getBoundingClientRect();

  return (
    <>
      <div
        className={cx({ groupsRow: true })}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <div style={{ paddingLeft: folderDepth * 35 + "px" }}></div>
        <div className={classes.folder}>
          <div className={classes.folderInner}>
            <IconButton
              aria-label="expand row"
              size="small"
              className={cx({
                folderIcon: true,
                active: open && (row.groups.length > 0 || row.tasks.length > 0),
                closedFolder:
                  !open && (row.groups.length > 0 || row.tasks.length > 0)
              })}
              onClick={() => setOpen(!open)}
            >
              <FolderIcon />
            </IconButton>
            <span className={classes.name}>{row.name}</span>
          </div>
        </div>
        <div className={classes.actions}>
          <div className={classes.actionInner}>
            {folderDepth < 4 && (
              <Button
                onClick={(e) => onMoreItemClick(e, "group")}
                classes={{
                  root: `${classes.btn} ${classes.addGroup}`
                }}
                ref={hoverAddGroupRef}
              >
                <AddGroupIcon />
              </Button>
            )}
            <Button
              onClick={(e) => onMoreItemClick(e, "task")}
              classes={{
                root: `${classes.btn}  ${classes.addTask}`
              }}
              ref={hoverAddTaskRef}
            >
              <AddTaskIcon />
            </Button>
            <Button
              onClick={handleEdit}
              classes={{
                root: `${classes.btn} ${classes.edit}`
              }}
              ref={hoverEditRef}
            >
              <CreateIcon />
            </Button>
            <Button
              onClick={handleDelete}
              classes={{
                root: `${classes.btn} ${classes.delete}`
              }}
              ref={hoverDeleteRef}
            >
              <DeleteIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.inner}>
        <div className={cx({ active: open })}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.groups.map((group, _index) => {
              return (
                <GroupsRow
                  onDelete={onDelete}
                  row={group}
                  index={`${index}_${_index}`}
                  createGroup={createGroup}
                  createTask={createTask}
                  projectId={projectId}
                  onEditGroup={onEditGroup}
                  onEditTask={onEditTask}
                  folderDepth={folderDepth + 1}
                  tasks={tasks}
                />
              );
            })}
            {row.tasks.length > 0 && (
              <div className={classes.tasks}>
                <TasksTable
                  tasks={row.tasks}
                  projectId={projectId}
                  onEdit={onEditTask}
                  folderDepth={folderDepth + 1}
                  groupId={row.id}
                  allTasks={tasks}
                />
              </div>
            )}
          </Collapse>
        </div>
      </div>
      {isEditHovered && (
        <div className={classes.editPopup}>
          <Popover
            text={"Редактировать"}
            coords={{
              top: editIconCoords.top,
              left: editIconCoords.left
            }}
            addedStyle={{
              transform: `translate(calc(-50% + 15px), -40px)`,
              width: "128px"
            }}
            arrow
          />
        </div>
      )}
      {isDeleteHovered && (
        <div className={classes.deletePopup}>
          <Popover
            text={"Удалить"}
            coords={{
              top: deleteIconCoords.top,
              left: deleteIconCoords.left
            }}
            addedStyle={{
              transform: `translate(calc(-50% + 15px), -40px)`,
              width: "80px"
            }}
            arrow
          />
        </div>
      )}
      {isAddGroupHovered && (
        <div className={classes.addGroupPopup}>
          <Popover
            text={"Добавить группу"}
            coords={{
              top: addGroupIconCoords.top,
              left: addGroupIconCoords.left
            }}
            addedStyle={{
              transform: `translate(calc(-50% + 15px), -40px)`,
              width: "143px"
            }}
            arrow
          />
        </div>
      )}
      {isAddTaskHovered && (
        <div className={classes.addTaskPopup}>
          <Popover
            text={"Добавить задачу"}
            coords={{
              top: addTaskIconCoords.top,
              left: addTaskIconCoords.left
            }}
            addedStyle={{
              transform: `translate(calc(-50% + 15px), -40px)`,
              width: "145px"
            }}
            arrow
          />
        </div>
      )}
    </>
  );
};
