import axios from "axios";
import * as types from "../types";
import { fetchTasks } from "./tasksActions";

export const fetchProjects = () => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/projects")
      .then((response) => {
        dispatch({ type: types.FETCH_PROJECTS, payload: response.data });
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createProject = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/projects", data)
      .then((response) => {
        dispatch(fetchProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const updateProject = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .patch(`/projects/${id}`, data)
      .then((response) => {
        dispatch(fetchProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const fetchProject = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get(`/projects/${id}`)
      .then((response) => {
        dispatch({ type: types.FETCH_PROJECT, payload: response.data });
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const deleteProject = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/projects/${id}`)
      .then((response) => {
        dispatch(fetchProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const importTasks = (projectId, importFrom) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post(`/projects/${projectId}/import/${importFrom}`)
      .then((response) => {
        dispatch(fetchProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const downloadReport = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get(`/projects/${projectId}/report`, {
        responseType: "arraybuffer"
      })
      .then((response) => {
        return response.data;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report_${projectId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};
