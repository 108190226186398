import React from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import classes from "./UsersTable.module.scss";
import { UsersRow } from "./UsersRow";
import { useDispatch } from "react-redux";
import {
  changeActiveStatus,
  resendInvitationAction
} from "../../store/actions/usersActions";
import { ModalWindow } from "../UI/ModalWindow/ModalWindow";

export const UsersTable = ({ users, handleEdit }) => {
  const dispatch = useDispatch();

  const [openBlockModal, setOpenBlockModal] = React.useState(false);
  const [blockValue, setBlockValue] = React.useState(null);

  const handleCloseBlockModal = () => {
    setOpenBlockModal(false);
  };

  const handleOpenBlockModal = () => {
    setOpenBlockModal(true);
  };

  const changeActivation = (id, value) => {
    if (value) {
      handleOpenBlockModal();
      setBlockValue({ id, value });
    } else {
      dispatch(changeActiveStatus(id, value));
    }
  };

  const resendInvitation = (id) => {
    dispatch(resendInvitationAction(id));
  };

  const editUser = (id) => {
    handleEdit(id);
  }

  const handleBlockUser = () => {
    dispatch(changeActiveStatus(blockValue.id, blockValue.value));
    handleCloseBlockModal();
  };

  return (
    <>
      <div className={classes.table_wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Номер</TableCell>
              <TableCell align="left" width={200}>
                Сотрудник
              </TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Ссылка-приглашение</TableCell>
              <TableCell align="left">Доступ</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row, index) => (
              <UsersRow
                key={index}
                row={row}
                index={index}
                changeActivation={changeActivation}
                resendInvitation={resendInvitation}
                onEdit={editUser}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      <ModalWindow
        isOpen={openBlockModal}
        handleClose={handleCloseBlockModal}
        title={"Заблокировать сотрудника"}
      >
        <p className={classes.block_info}>
          Вы уверены, что хотите заблокировать сотрудника?
        </p>
        <div className={classes.button_wrapper}>
          <Button
            classes={{
              root: classes.cancel_btn
            }}
            onClick={handleCloseBlockModal}
            variant="contained"
            type="submit"
            disableElevation
          >
            Отменить
          </Button>
          <Button
            classes={{
              root: classes.submit_btn
            }}
            onClick={handleBlockUser}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Заблокировать
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};
