import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";
import { CustomSelect } from "../UI/CustomSelect";
import { fetchUsers } from "../../store/actions/usersActions";

import classes from "./ConstructionControlProjectForm.module.scss";

export const ConstructionControlProjectForm = ({ onSubmit, onCancel, editedId }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ employees }) => employees);

  const [currentProject, setCurrentProject] = React.useState({});
  const [form, setForm] = React.useState({});
  const [responsibleEmployee, setResponsibleEmployee] = React.useState(null);
  const [ptoEmployee, setPtoEmployee] = React.useState(null);

  React.useEffect(() => {
    if (editedId) {
      fetchProject();
    }
  }, []);

  React.useEffect(() => {
    setForm(currentProject);
    setResponsibleEmployee(currentProject.responsible_employee?.id);
    setPtoEmployee(currentProject.pto_employee?.id);
  }, [currentProject]);

  const fetchProject = async () => {
    await axios
      .get(`/construction_control/${editedId}`)
      .then((response) => {
        setCurrentProject(response.data);
      })
      .catch((err) => console.log(err));
  };

  const beforeSubmit = (values) => {
    onSubmit({
      ...values,
      title: values.title ?? currentProject.title,
      check_dates_str: values.check_dates_str ?? currentProject.check_dates_str,
      responsible_employee_id: responsibleEmployee,
      pto_employee_id: ptoEmployee,
    });
  };

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название проекта*"}
                component={CustomTextField}
                name="title"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.title,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: responsibleEmployee,
                  update: setResponsibleEmployee,
                  label: "Ответственный сотрудник",
                  placeholder: "Ответственный сотрудник",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: ptoEmployee,
                  update: setPtoEmployee,
                  label: "Ответственный сотрудник ПТО",
                  placeholder: "Ответственный сотрудник ПТО",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <Field
                placeholder={"Даты проверок"}
                component={CustomTextField}
                name="check_dates_str"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.check_dates_str,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
                multiline
              />
              <b>Даты проверок (по одной в строку в формате ДД.ММ.ГГГГ)</b>
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              type="submit"
              disableElevation
              disabled={
                !form.title ||
                !form.check_dates_str ||
                !responsibleEmployee
              }
            >
              Сохранить
            </Button>
          </div>
          </div>
        </form>
      )}
    />
  );
};
