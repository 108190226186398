import * as React from "react";

const SvgArrowUpPlus = (props) => (
  <svg
    width={10}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.875 3.625-3.5 3.491H3v6.134h1.75V7.116h2.625l-3.5-3.491ZM8.125 0h-1.25v1.875H5v1.25h1.875V5h1.25V3.125H10v-1.25H8.125V0Z"
      fill="#000"
    />
  </svg>
);

export default SvgArrowUpPlus;
