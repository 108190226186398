export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const AUTH_ME_ERROR = "AUTH_ME_ERROR";

export const SET_LOADING = "SET_LOADING";
export const SET_USER_DATA = "SET_USER_DATA";

export const FETCH_USERS = "FETCH_USERS";

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const FETCH_TASKS = "FETCH_TASKS";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUP = "FETCH_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";

export const FETCH_CONTRACTOR = "FETCH_CONTRACTOR";
export const FETCH_CONTRACTORS = "FETCH_CONTRACTORS";

export const FETCH_CCPROJECT = "FETCH_CCPROJECT";
export const FETCH_CCPROJECTS = "FETCH_CCPROJECTS";
