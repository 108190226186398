import axios from "axios";
import { getWithExpiry } from "../utils/localStorageWithExpiry";

if (process.env.REACT_APP_BACKEND_ADDR) {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ADDR;
} else {
  axios.defaults.baseURL = "https://ksi-test.appvelox.ru/api/";
}

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = getWithExpiry("access_token");
  const tokenType = getWithExpiry("tokenType");
  config.headers.Authorization = "bearer " + token;

  return config;
});
