import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const helperTextStyles = makeStyles((theme) => ({
  error: {
    "&.MuiFormHelperText-root.Mui-error": {
      marginLeft: "0px"
    }
  }
}));

const CustomTextField = ({
  label,
  input,
  placeholder,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const helperTestClasses = helperTextStyles();

  return (
    <TextField
      {...input}
      {...custom}
      // value={custom.inputProps?.value}
      // onChange={custom.inputProps?.onChange}
      label={label}
      placeholder={placeholder || label}
      error={touched && invalid}
      FormHelperTextProps={{ classes: helperTestClasses }}
      helperText={(touched && error) || custom.helperText}
    />
  );
};

export default CustomTextField;
