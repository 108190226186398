import * as React from "react";

const SvgUnlink = (props) => (
  <svg
    width={21}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.875 4.125h-3.5v1.662h3.5A2.715 2.715 0 0 1 17.587 8.5a2.702 2.702 0 0 1-2.02 2.607l1.277 1.278c1.426-.726 2.406-2.179 2.406-3.885a4.377 4.377 0 0 0-4.375-4.375ZM14 7.625h-1.916l1.75 1.75H14v-1.75ZM1.75 1.736l2.721 2.721A4.367 4.367 0 0 0 1.75 8.5a4.377 4.377 0 0 0 4.375 4.375h3.5v-1.662h-3.5A2.715 2.715 0 0 1 3.413 8.5a2.71 2.71 0 0 1 2.414-2.686L7.64 7.625H7v1.75h2.389l1.986 1.986v1.514h1.514l3.509 3.5 1.102-1.102L2.861.625 1.75 1.736Z"
      fill="#000"
    />
  </svg>
);

export default SvgUnlink;
