import * as types from "../types";

const initialState = {
  cc_projects: []
};

export function constructionControlReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CCPROJECTS:
      return {
        ...state,
        cc_projects: action.payload
      };
    default:
      return state;
  }
}
