import React from "react";
import { Link } from "react-router-dom";
import { TableCell, TableRow, Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import ReplyIcon from "@material-ui/icons/Reply";
import { DeleteIcon } from "../../UI/Svgs";

import classes from "./ConstructionControlProjectsRow.module.scss";

export const ConstructionControlProjectsRow = ({ row, index, onEdit, onImport, onDelete }) => {
  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(row.id);
  };

  const handleImport = (e) => {
    e.preventDefault();
    onImport(row.id);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete(row.id);
  };

  return (
    <>
      <TableRow className={classes.projectsRow}>
        <TableCell className={classes.gray} width={120}>
          {index + 1}
        </TableCell>
        <TableCell width={400}>
          <div className={classes.nameInner}>
            <span className={classes.name}>{row.title}</span>
          </div>
        </TableCell>
        <TableCell width={160}>
          <div className={classes.actions}>
            <Button
              onClick={handleEdit}
              classes={{
                root: classes.btn
              }}
            >
              <CreateIcon />
            </Button>
            <Button
              onClick={handleDelete}
              classes={{
                root: classes.btn
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
