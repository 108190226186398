import React from "react";

import MainContainer from "../../components/MainContainer/MainContainer";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";

import { UsersTable } from "../../components/UsersTable";
import AddIcon from "@material-ui/icons/Add";

import classes from "./Users.module.scss";
import { UsersForm } from "../../components/UsersForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, inviteEmpoyee, updateEmployee } from "../../store/actions/usersActions";
import { Button } from "@material-ui/core";

export const Users = () => {
  const { users } = useSelector(({ employees }) => employees);
  const dispatch = useDispatch();

  const [editedId, setEditedId] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditedId(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEdit = (id) => {
    setEditedId(id);
    handleOpenModal();
  };

  const handleSubmit = (values) => {
    if (!editedId) {
      dispatch(inviteEmpoyee(values));
    } else {
      dispatch(updateEmployee(editedId, values));
    }
    handleCloseModal();
  };

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <>
      <MainContainer>
        <div className={classes.users}>
          <div className={classes.add_wrapper}>
            <Button
              variant="contained"
              classes={{
                root: classes.add_btn
              }}
              onClick={handleOpenModal}
            >
              <span className={classes.btn_text}>Добавить</span>
            </Button>
          </div>
          {users?.length > 0 ? (
            <section className={classes.tableWrapper}>
              <div className={classes.title}>Сотрудники</div>
              <UsersTable users={users} handleEdit={handleEdit}/>
            </section>
          ) : (
            <div className={classes.no_rows}>
              <div className={classes.text}>
                Чтобы пригласить пользователя в чат-бот, нажмите кнопку
                “Добавить”
              </div>
            </div>
          )}
        </div>
      </MainContainer>
      <ModalWindow
        isOpen={openModal}
        handleClose={handleCloseModal}
        title={editedId ? "Редактировать сотрудника" : "Пригласить сотрудника"}
      >
        <UsersForm onCancel={handleCloseModal} onSubmit={handleSubmit} editedId={editedId}/>
      </ModalWindow>
    </>
  );
};
