import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: 560,
    whiteSpace: "unset",
    wordBreak: "break-all"
  }
}));

export const CustomSelect = ({
  inputProps: {
    items,
    label,
    placeholder,
    update,
    activeItem,
    variant,
    emptyVariant
  }
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    update(event.target.value);
  };

  const getTitle = (item) => {
    if (item.group === undefined) {
      return item.name;
    } else {
      return item.group.name + ". " + item.name;
    }
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          {placeholder}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={activeItem || ""}
          onChange={handleChange}
          label={placeholder}
          fullWidth
        >
          {items.length > 0
            ? items.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  className={classes.formControl}
                >
                  {getTitle(item)}
                </MenuItem>
              ))
            : emptyVariant && (
                <MenuItem value="" disabled>
                  {emptyVariant}
                </MenuItem>
              )}
        </Select>
      </FormControl>
    </div>
  );
};
