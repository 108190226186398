import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import classes from "./ModalWindow.module.scss";

export function ModalWindow({ title, isOpen, handleClose, children }) {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialog,
          paperWidthSm: classes.dialog_paper
        }}
      >
        {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
        <DialogContent className={classes.content}>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
