import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";

import CustomTextField from "../../components/UI/CustomTextField";
import { required } from "../../utils/validators";
import { sendLoginData } from "../../store/actions/loginActions";

import classes from "./Login.module.scss";

export const Login = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.login.isAuth);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuth) {
      console.log("redirecting to /");
      navigate("/", { replace: true });
    }
  }, [isAuth]);

  const handleSubmit = (values) => {
    dispatch(sendLoginData(values.login, values.password));
  };

  return (
    <div className={classes.LoginPage}>
      <section className={classes.info_section}>
        <div className={classes.title}>
          <img src="/assets/login-logo.svg" alt="" width="300" height="140" />
        </div>
        <h2 className={classes.subtitle}>
          Добро пожаловать в систему управления
        </h2>
      </section>
      <section className={classes.form_section}>
        <LoginForm onSubmit={handleSubmit} />
      </section>
    </div>
  );
};

const LoginForm = ({ onSubmit }) => {
  const isError = useSelector((state) => !!state.login.error);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.form_header}>
            <h3 className={classes.form_title}>Войти</h3>
            {isError && (
              <div className={classes.form_error}>
                Логин или пароль введены неверно. Попробуйте еще раз или
                обратитесь к администратору
              </div>
            )}
          </div>
          <div className={classes.input_wrapper}>
            <Field
              label="Логин:"
              validate={required}
              component={CustomTextField}
              name="login"
              variant="outlined"
            />
            <Field
              label="Пароль:"
              validate={required}
              component={CustomTextField}
              name="password"
              type={"password"}
              variant="outlined"
            />
          </div>

          <div className={classes.button_wrapper}>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              fullWidth
              type="submit"
              disableElevation
            >
              Войти
            </Button>
          </div>
        </form>
      )}
    />
  );
};
