import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";
import { CustomDateInput } from "../UI/CustomDatePicker";
import { CustomSelect } from "../UI/CustomSelect";
import { fetchUsers } from "../../store/actions/usersActions";
import { fetchProjects } from "../../store/actions/projectsActions";

import classes from "./ContractorForm.module.scss";

export const ContractorForm = ({ onSubmit, onCancel, editedId }) => {
  const work_types = [
    {"name": "Ограждение котлована", "id": "Ограждение котлована"},
    {"name": "Устройство свай", "id": "Устройство свай"},
    {"name": "Разработка котлована", "id": "Разработка котлована"},
    {"name": "Монолитные работы", "id": "Монолитные работы"},
    {"name": "Кирпичная кладка", "id": "Кирпичная кладка"},
    {"name": "Кладка наружных стен облицовки фасада", "id": "Кладка наружных стен облицовки фасада"},
    {"name": "Устройство фасада (декор.панели)", "id": "Устройство фасада (декор.панели)"},
    {"name": "Устройство вент.фасада", "id": "Устройство вент.фасада"},
    {"name": "Окна и витражи", "id": "Окна и витражи"},
    {"name": "Промышленные полы ниже отм. +0.000", "id": "Промышленные полы ниже отм. +0.000"},
    {"name": "Отделка технических помещений", "id": "Отделка технических помещений"},
    {"name": "Внутренняя отделка", "id": "Внутренняя отделка"},
    {"name": "Отделка шахты лифта", "id": "Отделка шахты лифта"},
    {"name": "АСКУЭ", "id": "АСКУЭ"},
    {"name": "Кровля основная", "id": "Кровля основная"},
    {"name": "Кровля эксплуатируемая", "id": "Кровля эксплуатируемая"},
    {"name": "Металлоконструкции", "id": "Металлоконструкции"},
    {"name": "Двери", "id": "Двери"},
    {"name": "Ворота", "id": "Ворота"},
    {"name": "Лифты", "id": "Лифты"},
    {"name": "Внутренние сети ОВК", "id": "Внутренние сети ОВК"},
    {"name": "Внутренние сети электроснабжения", "id": "Внутренние сети электроснабжения"},
    {"name": "Внутренние слаботочные сети", "id": "Внутренние слаботочные сети"},
    {"name": "Система сплинклерного пожаротушения", "id": "Система сплинклерного пожаротушения"},
    {"name": "Внутренние сети газоснабжения", "id": "Внутренние сети газоснабжения"},
    {"name": "Внутренние сети вентиляционной системы", "id": "Внутренние сети вентиляционной системы"},
    {"name": "Наружные сети газопровода", "id": "Наружные сети газопровода"},
    {"name": "Наружное освещение", "id": "Наружное освещение"},
    {"name": "Наружные сети водоснабжения и канализации", "id": "Наружные сети водоснабжения и канализации"},
    {"name": "Наружные сети связи", "id": "Наружные сети связи"},
    {"name": "Благоустройство", "id": "Благоустройство"},
    {"name": "МАФы", "id": "МАФы"},
  ];

  const dispatch = useDispatch();
  const { users } = useSelector(({ employees }) => employees);
  const { projects } = useSelector(({ projects }) => projects);

  const [currentContractor, setCurrentContractor] = React.useState({});
  const [form, setForm] = React.useState({});
  const [date, setDate] = React.useState(null);
  const [project, setProject] = React.useState(null);
  const [type_of_work, setTypeOfWork] = React.useState("");
  const [responsibleEmployee, setResponsibleEmployee] = React.useState(null);
  const [laborProtectionSpecialist, setLaborProtectionSpecialist] = React.useState(null);
  const [isValidDate, setIsValidDate] = React.useState(true);

  React.useEffect(() => {
    if (editedId) {
      fetchContractor();
    }
  }, []);

  React.useEffect(() => {
    if (date instanceof Date && !isNaN(date)) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  }, [date]);

  React.useEffect(() => {
    setForm(currentContractor);
    setProject(currentContractor.project?.id)
    setTypeOfWork(currentContractor.type_of_work);
    setResponsibleEmployee(currentContractor.responsible_employee?.id);
    setLaborProtectionSpecialist(currentContractor.labor_protection_specialist_employee?.id);

    if (currentContractor.work_start_date) {
      setDate(new Date(currentContractor.work_start_date));
    } else {
      setDate(null);
    }
  }, [currentContractor]);

  const fetchContractor = async () => {
    await axios
      .get(`/contractors/${editedId}`)
      .then((response) => {
        setCurrentContractor(response.data);
      })
      .catch((err) => console.log(err));
  };

  const beforeSubmit = (values) => {
    onSubmit({
      ...values,
      title: values.title ?? currentContractor.title,
      project_id: project,
      type_of_work: type_of_work,
      contract_amount: values.contract_amount ?? currentContractor.contract_amount,
      teo: values.teo ?? currentContractor.teo,
      responsible_employee_id: responsibleEmployee,
      labor_protection_specialist_employee_id: laborProtectionSpecialist,
      date
    });
  };

  React.useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchProjects());
  }, []);

  // React.useEffect(() => {
  //   dispatch(fetchProjects());
  // }, []);

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название подрядчика*"}
                component={CustomTextField}
                name="title"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.title,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: projects,
                  activeItem: project,
                  update: setProject,
                  label: "Проект*",
                  placeholder: "Проект*",
                  emptyVariant: "Нет проектов для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: responsibleEmployee,
                  update: setResponsibleEmployee,
                  label: "Ответственный сотрудник",
                  placeholder: "Ответственный сотрудник",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: laborProtectionSpecialist,
                  update: setLaborProtectionSpecialist,
                  label: "Специалист ОТ",
                  placeholder: "Специалист ОТ",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <Field
                placeholder={"Сумма контракта"}
                component={CustomTextField}
                name="contract_amount"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.contract_amount,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <Field
                placeholder={"ТЭО"}
                component={CustomTextField}
                name="teo"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.teo,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: work_types,
                  activeItem: type_of_work,
                  update: setTypeOfWork,
                  label: "Вид работ",
                  placeholder: "Вид работ",
                }}
              />
            </div>
            <div className={classes.input_item}>
                <CustomDateInput
                  placeholder={"Дата выхода на объект"}
                  input={{ value: date, onChange: (date) => setDate(date) }}
                />
              </div>
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              type="submit"
              disableElevation
              disabled={
                !form.title ||
                !responsibleEmployee ||
                !laborProtectionSpecialist ||
                !isValidDate
              }
            >
              Сохранить
            </Button>
          </div>
        </form>
      )}
    />
  );
};
