import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";
import { CustomDateInput } from "../UI/CustomDatePicker";
import { CustomSelect } from "../UI/CustomSelect";
import { fetchUsers } from "../../store/actions/usersActions";

import classes from "./ReassignForm.module.scss";

export const ReassignForm = ({ onSubmit, onCancel }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ employees }) => employees);
  const statuses = [
    {"name": "Да", "id": "yes"},
    {"name": "Нет", "id": "no"},
  ];

  const [old_user, setOldUser] = React.useState(null);
  const [new_user, setNewUser] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  const beforeSubmit = (values) => {
    onSubmit({
      ...values,
      old_employee_id: old_user,
      new_employee_id: new_user,
      only_previously_reassigned: status
    });
  };

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: old_user,
                  update: setOldUser,
                  label: "Текущий исполнитель",
                  placeholder: "Текущий исполнитель",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: new_user,
                  update: setNewUser,
                  label: "Новый исполнитель",
                  placeholder: "Новый исполнитель",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: statuses,
                  activeItem: status,
                  update: setStatus,
                  label: "Только ранее переназначенные",
                  placeholder: "Только ранее переназначенные",
                }}
              />
            </div>
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              type="submit"
              disableElevation
              disabled={
                !old_user || !new_user || !status
              }
            >
              Сохранить
            </Button>
          </div>
        </form>
      )}
    />
  );
};
