import React from "react";
import { Field, Form } from "react-final-form";
import { Button } from "@material-ui/core";

import CustomTextField from "../UI/CustomTextField";
import { CustomSelect } from "../UI/CustomSelect";
import { required } from "../../utils/validators";
import axios from "axios";

import classes from "./UsersForm.module.scss";

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const valid = (input) => {
  let valid = true;
  if (input.length <= 0) return false;
  if (!emailRegex.test(input)) {
    valid = false;
  }
  return valid;
};

export const UsersForm = ({ onSubmit, onCancel, editedId }) => {
  const [emailValue, setEmailValue] = React.useState("");
  const [nameValue, setNameValue] = React.useState("");
  const [chat_id, setChat] = React.useState(null);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [currentEmployee, setCurrentEmployee] = React.useState({});

  React.useEffect(() => {
    if (editedId) {
      fetchEmployee();
    }
  }, []);

  React.useEffect(() => {
    setNameValue(currentEmployee.name);
    setChat(currentEmployee.chat_id);
  }, [currentEmployee]);

  const changeInput = ({ target }) => {
    if (target.name === "email") {
      setEmailValue(target.value);

      const isValidInput = valid(target.value);
      setIsValidEmail(isValidInput);
    } else if (target.name === "name") {
      setNameValue(target.value);
    }
  };

  const fetchEmployee = async () => {
    await axios
      .get(`/employees/${editedId}`)
      .then((response) => {
        setCurrentEmployee(response.data);
      })
      .catch((err) => console.log(err));
  };

  const chats = [
    {"name": "Задачи КамаСтройИнвест", "id": "-1001544230739"},
    {"name": "Сети КамаСтройИнвест", "id": "-755486785"},
    {"name": "ПТО КамаСтройИнвест", "id": "-688416496"},
  ];

  const beforeSubmit = (values) => {
    if (editedId) {
      onSubmit({
        chat_id: chat_id,
      });
    } else {
      onSubmit({
        name: values.name || currentEmployee.name,
        email: values.email || currentEmployee.email,
        chat_id: chat_id,
      });
    }
  };

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            {editedId? <></> : (
            <div className={classes.input_item}>
              <Field
                placeholder={"Email*"}
                component={CustomTextField}
                name="email"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: emailValue,
                  onChange: changeInput
                }}
                validate={required}
                fullWidth
              />
            </div>
            )}
            {editedId? <></> : (
            <div className={classes.input_item}>
              <Field
                placeholder={"ФИО*"}
                component={CustomTextField}
                name="name"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: nameValue,
                  onChange: changeInput
                }}
                validate={required}
                fullWidth
              />
            </div>
            )}
            <div className={classes.inputProps}>
              <CustomSelect
                inputProps={{
                  items: chats,
                  activeItem: chat_id,
                  update: setChat,
                  label: "Чат",
                  placeholder: "Чат",
                }}
              />
            </div>
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            {editedId? (
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              disabled={!nameValue}
            >
              Обновить
            </Button>) : (
              <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              disabled={!values.name || !isValidEmail || !chat_id}
            >
              Отправить ссылку
            </Button>
            )}
          </div>
        </form>
      )}
    />
  );
};
