import { Button } from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MainContainer from "../../components/MainContainer/MainContainer";
import { ConstructionControlProjectForm } from "../../components/ConstructionControlProjectForm";
import { ConstructionControlProjectsTable } from "../../components/ConstructionControlProjectsTable";
import { ModalWindow } from "../../components/UI/ModalWindow/ModalWindow";
import {
  createCCProject,
  deleteCCProject,
  fetchCCProjects,
  updateCCProject,
  downloadConstructionControlReport
} from "../../store/actions/constructionControlActions";

import classes from "./ConstructionControl.module.scss";

export const ConstructionControl = () => {
  const dispatch = useDispatch();
  const { cc_projects } = useSelector(({ cc_projects }) => cc_projects);

  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [editedId, setEditedId] = React.useState(null);
  const [deletedId, setDeletedId] = React.useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditedId(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmit = ({ title, responsible_employee_id, pto_employee_id, check_dates_str }) => {
    if (!editedId) {
      dispatch(createCCProject({ title, responsible_employee_id, pto_employee_id, check_dates_str }));
    } else {
      dispatch(updateCCProject(editedId, { title, responsible_employee_id, pto_employee_id, check_dates_str }));
    }

    handleCloseModal();
  };

  const handleEdit = (id) => {
    setEditedId(id);
    handleOpenModal();
  };

  const handleDelete = (id) => {
    setDeletedId(id);
    setOpenDeleteModal(true);
  };

  const handleDeleteProject = () => {
    dispatch(deleteCCProject(deletedId));
    setOpenDeleteModal(false);
  };

  const handleOnClick = () => {
    dispatch(downloadConstructionControlReport());
  };

  React.useEffect(() => {
    dispatch(fetchCCProjects());
  }, []);

  return (
    <>
      <MainContainer>
        <div className={classes.projects}>
          <div className={classes.buttons_wrapper}>
            <div className={classes.add_wrapper}>
              <Button
                variant="contained"
                classes={{
                  root: classes.add_btn
                }}
                onClick={handleOpenModal}
              >
                <span className={classes.btn_text}>Добавить</span>
              </Button>
            </div>
            <div className={classes.export_wrapper}>
              <Button
                variant="contained"
                classes={{
                  root: classes.export_btn
                }}
                onClick={handleOnClick}
              >
                <span className={classes.btn_text}>Выгрузка</span>
              </Button>
            </div>
          </div>
          {cc_projects?.length > 0 ? (
            <section className={classes.tableWrapper}>
              <div className={classes.title}>Проекты</div>
              <ConstructionControlProjectsTable
                cc_projects={cc_projects}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </section>
          ) : (
            <div className={classes.no_rows}>
              <div className={classes.text}>
                Чтобы создать проект, нажмите кнопку “Добавить”
              </div>
            </div>
          )}
        </div>
      </MainContainer>
      <ModalWindow
        isOpen={openModal}
        handleClose={handleCloseModal}
        title={editedId ? "Изменить проект" : "Создать проект"}
      >
        <ConstructionControlProjectForm
          onCancel={handleCloseModal}
          onSubmit={handleSubmit}
          editedId={editedId}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        title={"Удалить проект"}
      >
        <p className={classes.block_info}>
          Вы уверены, что хотите удалить проект?
        </p>
        <div className={classes.button_wrapper}>
          <Button
            classes={{
              root: classes.cancel_btn
            }}
            onClick={() => setOpenDeleteModal(false)}
            variant="contained"
            disableElevation
          >
            Отменить
          </Button>
          <Button
            classes={{
              root: classes.submit_btn
            }}
            onClick={handleDeleteProject}
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
          >
            Удалить
          </Button>
        </div>
      </ModalWindow>
    </>
  );
};
