import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { Login } from "../../pages/Login/Login";
import { Users } from "../../pages/Users";
import { Tasks } from "../../pages/Tasks";
import { Projects } from "../../pages/Projects";
import { ConstructionControl } from "../../pages/ConstructionControl";
import { Contractors } from "../../pages/Contractors";

import { getWithExpiry } from "../../utils/localStorageWithExpiry";

export function App() {
  const { isAuth } = useSelector((store) => store.login);
  const token = getWithExpiry("access_token");
  const username = getWithExpiry("username");

  if (!token) {
    return <Login />;
  }

  if (username === "admin") {
    return (
      <Routes>
        <Route path="/" element={<Users />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<Tasks />} />
        <Route path="/construction_control" element={<ConstructionControl />} />
        <Route path="/contractors" element={<Contractors />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<ConstructionControl />} />
        <Route path="/contractors" element={<Contractors />} />
      </Routes>
    );
  }
}
