import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";

import { ProjectsRow } from "./ProjectsRow";
import { ImportTasksModal } from "../ImportTasksModal";
import { importTasks } from "../../store/actions/projectsActions";

import classes from "./ProjectsTable.module.scss";

export const ProjectsTable = ({ projects, handleEdit, handleDelete }) => {
  const dispatch = useDispatch();

  const [openImportModal, setOpenImportModal] = React.useState(false);
  const [projectId, setProjectId] = React.useState(null);

  const handleOpenImportModal = (id) => {
    setProjectId(id);
    setOpenImportModal(true);
  };

  const handleCloseImportModal = () => {
    setOpenImportModal(false);
  };

  const onEdit = (id, value) => {
    handleEdit(id);
  };

  const onDelete = (id, value) => {
    handleDelete(id);
  };

  const onImport = (importId) => {
    dispatch(importTasks(projectId, importId));
    handleCloseImportModal();
  };

  return (
    <>
      <div className={classes.table_wrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Номер</TableCell>
              <TableCell align="left">Название проекта</TableCell>
              <TableCell align="left">Дата</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((row, index) => (
              <ProjectsRow
                key={index}
                row={row}
                index={index}
                onEdit={onEdit}
                onImport={handleOpenImportModal}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      <ImportTasksModal
        openImportModal={openImportModal}
        handleCloseImportModal={handleCloseImportModal}
        onImport={onImport}
        projects={projects}
      />
    </>
  );
};
