import ReactDOM from "react-dom";

import { Popup } from "../Popup/Popup";

import classes from "./Popover.module.scss";

export const Popover = ({ text, coords, addedStyle, children, ...custom }) => {
  return ReactDOM.createPortal(
    <div
      className={classes.popover}
      style={{ top: coords.top, left: coords.left, ...addedStyle }}
    >
      <Popup text={text} {...custom}>
        {children}
      </Popup>
    </div>,
    document.querySelector("#root")
  );
};
