import * as React from "react";

const SvgArrowUp = (props) => (
  <svg
    width={8}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.875.625-3.5 3.491H3v6.134h1.75V4.116h2.625L3.875.625Z"
      fill="#000"
    />
  </svg>
);

export default SvgArrowUp;
