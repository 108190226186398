import React from "react";
import {
  makeStyles,
  IconButton,
  Collapse,
  Box,
  Button
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CreateIcon from "@material-ui/icons/Create";

import classNames from "classnames/bind";

import { CustomSelect } from "../../UI/CustomSelect";
import { Popup } from "../../UI/Popup/Popup";
import { Popover } from "../../UI/Popover/Popover";
import { DeleteIcon, UserIcon } from "../../UI/Svgs";
import {
  addTaskRelation,
  removeTaskRelation
} from "../../../store/actions/tasksActions";
import { useHover } from "../../../utils/hooks/useHover";
import { CurrentTask } from "./CurrentTask";
import { InnerTask } from "./InnerTask";

import classes from "./TasksRow.module.scss";

const cx = classNames.bind(classes);

export const declOfNum = function (number, titles) {
  var cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  },
  innerTable: {
    borderBottom: "0",
    innerTable: {
      borderBottom: "unset"
    }
  },
  innerCell: {
    padding: "0px",
    borderBottom: "unset"
  },
  sticky: {}
});

export const TasksRow = ({
  row,
  onEdit,
  onDelete,
  projectId,
  countCollapseHovered,
  countUnlinkHovered,
  countAddPrevHovered,
  countAddNextHovered,
  handleShowLinkedTask,
  refs,
  activeTask,
  openTask,
  toggleCollapseTasks,
  folderDepth,
  allTasks,
  showAddPrevTask,
  setShowAddPrevTask,
  showAddNextTask,
  setShowAddNextTask,
  countEditHovered,
  countDeleteHovered
}) => {
  const open = openTask === row.id;
  const styles = useRowStyles();
  const dispatch = useDispatch();

  const addPrevTask = (value) => {
    dispatch(
      addTaskRelation({
        id: row.id,
        relationId: value,
        type: "previous",
        projectId
      })
    );
    setShowAddPrevTask(false);
  };

  const addNextTask = (value) => {
    dispatch(
      addTaskRelation({
        id: row.id,
        relationId: value,
        type: "next",
        projectId
      })
    );
    setShowAddNextTask(false);
  };

  const removeLink = (id) => {
    dispatch(
      removeTaskRelation({
        id: row.id,
        relationId: id,
        projectId
      })
    );
  };

  const [hoverCollapseRef, isCollapseHovered] = useHover(countCollapseHovered);
  const [hoverEditRef, isEditHovered] = useHover(countEditHovered);
  const [hoverDeleteRef, isDeleteHovered] = useHover(countDeleteHovered);

  const editIconCoords = hoverEditRef.current?.getBoundingClientRect();
  const deleteIconCoords = hoverDeleteRef.current?.getBoundingClientRect();

  return (
    <>
      <div
        className={cx({
          tasksRow: true,
          rowSelect: open,
          active: activeTask === row.id
        })}
        style={{ paddingLeft: folderDepth * 35 + 30 + "px" }}
        ref={refs[row.id]}
      >
        <div className={`${classes.expand}`}>
          <div className={classes.expandInner}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => toggleCollapseTasks(row.id)}
              ref={hoverCollapseRef}
              className={classes.expandIcon}
            >
              {openTask === row.id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {isCollapseHovered && countCollapseHovered.current < 3 && (
                <div className={classes.popup}>
                  <Popup
                    text={
                      openTask !== row.id ? "Показать связи" : "Скрыть связи"
                    }
                    arrow
                  />
                </div>
              )}
            </IconButton>
            <span className={classes.name}>{row.name}</span>
          </div>
        </div>
        <div className={classes.gray}>
          {row.employee?.name.length > 49
            ? row.employee?.name.split(0, 47) + "..."
            : row.employee?.name || "Нет исполнителя"}
        </div>
        <div className={classes.gray} width={110}>
          {row.dynamic_start ? new Date(row.dynamic_start).toLocaleDateString("ru-RU") : ""}
        </div>
        <div className={classes.gray} width={150}>
          {row.dynamic_end
            ? new Date(row.dynamic_end).toLocaleDateString("ru-RU")
            : ""}
        </div>
        <div className={classes.gray} width={150}>
          {row.actual_end
            ? new Date(row.actual_end).toLocaleDateString("ru-RU")
            : ""}
        </div>
        <div width={100}>
          <div className={classes.actions}>
            <div className={classes.edit}>
              <Button
                onClick={() => onEdit(row.id)}
                classes={{
                  root: `${classes.btn} ${
                    isEditHovered ? classes.hoveredGreen : ""
                  }`
                }}
                ref={hoverEditRef}
              >
                <CreateIcon />
              </Button>
              {isEditHovered && countEditHovered.current < 3 && (
                <div className={classes.popup}>
                  <Popover
                    text={"Редактировать задачу"}
                    coords={{
                      top: editIconCoords.top,
                      left: editIconCoords.left
                    }}
                    addedStyle={{
                      transform: `translate(calc(-50% + 20px), -40px)`,
                      width: "186px"
                    }}
                    arrow
                  />
                </div>
              )}
            </div>
            <div className={classes.delete}>
              <Button
                onClick={() => onDelete(row.id)}
                classes={{
                  root: `${classes.btn} ${
                    isDeleteHovered ? classes.hoveredRed : ""
                  }`
                }}
                ref={hoverDeleteRef}
              >
                <DeleteIcon />
              </Button>
              {isDeleteHovered && countDeleteHovered.current < 3 && (
                <div className={classes.popup}>
                  <Popover
                    text={"Удалить задачу"}
                    coords={{
                      top: deleteIconCoords.top,
                      left: deleteIconCoords.left
                    }}
                    addedStyle={{
                      transform: `translate(calc(-50% + 17px), -40px)`,
                      width: "135px"
                    }}
                    arrow
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.innerTable} ${open ? classes.rowSelect : ""}`}>
        <div
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
          className={styles.innerCell}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <div className={classes.prevTasks}>
                {row.previous_tasks.map((task) => (
                  <InnerTask
                    {...task}
                    removeLink={removeLink}
                    countUnlinkHovered={countUnlinkHovered}
                    onEdit={onEdit}
                    handleShowLinkedTask={handleShowLinkedTask}
                    folderDepth={folderDepth}
                    type={"prev"}
                  />
                ))}
                {showAddPrevTask && (
                  <div
                    className={classes.taskSelect}
                    style={{
                      paddingLeft: folderDepth * 35 + 95 + "px"
                    }}
                  >
                    <div>
                      <CustomSelect
                        inputProps={{
                          items:
                            allTasks?.filter(
                              (task) =>
                                task.id !== row.id &&
                                !row.next_tasks
                                  .map((task) => task.id)
                                  .includes(task.id) &&
                                !row.previous_tasks
                                  .map((task) => task.id)
                                  .includes(task.id)
                            ) || [],
                          placeholder: "Выберете задачу",
                          emptyVariant: "Нет задач для связи",
                          update: addPrevTask
                        }}
                      />
                    </div>
                    <div>
                      <UserIcon />
                    </div>
                    {row.start ? <div>Начало</div> : "—"}
                    {row.dynamic_end ? <div>Окончание (план)</div> : "—"}
                    {row.actual_end ? <div>Окончание (факт)</div> : "—"}
                  </div>
                )}
              </div>
              <CurrentTask
                row={row}
                setShowAddNextTask={() => setShowAddNextTask(true)}
                setShowAddPrevTask={() => setShowAddPrevTask(true)}
                countAddPrevHovered={countAddPrevHovered}
                countAddNextHovered={countAddNextHovered}
                folderDepth={folderDepth}
              />
              <div className={classes.nextTasks}>
                {showAddNextTask && (
                  <div
                    className={classes.taskSelect}
                    style={{
                      paddingLeft: folderDepth * 35 + 95 + "px"
                    }}
                  >
                    <div>
                      <CustomSelect
                        inputProps={{
                          items:
                            allTasks?.filter(
                              (task) =>
                                task.id !== row.id &&
                                !row.previous_tasks
                                  .map((task) => task.id)
                                  .includes(task.id) &&
                                !row.next_tasks
                                  .map((task) => task.id)
                                  .includes(task.id)
                            ) || [],
                          placeholder: "Выберете задачу",
                          emptyVariant: "Нет задач для связи",
                          update: addNextTask
                        }}
                      />
                    </div>
                    <div>
                      <UserIcon />
                    </div>
                    {row.start ? <div>Начало</div> : "—"}
                    {row.dynamic_end ? <div>Окончание (план)</div> : "—"}
                    {row.actual_end ? <div>Окончание (факт)</div> : "—"}
                  </div>
                )}
                {row.next_tasks.map((task) => (
                  <InnerTask
                    {...task}
                    onEdit={onEdit}
                    removeLink={removeLink}
                    countUnlinkHovered={countUnlinkHovered}
                    handleShowLinkedTask={handleShowLinkedTask}
                    folderDepth={folderDepth}
                    type={"next"}
                  />
                ))}
              </div>
            </Box>
          </Collapse>
        </div>
      </div>
    </>
  );
};
