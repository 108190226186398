import React from "react";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";
import { required } from "../../utils/validators";

import classes from "./ProjectsForm.module.scss";
import { CustomDateInput } from "../UI/CustomDatePicker";
import { CustomSelect } from "../UI/CustomSelect";
import axios from "axios";

export const ProjectsForm = ({ onSubmit, onCancel, editedId }) => {
  const colors = [
    {"name": "Белый", "id": "white"},
    {"name": "Черный", "id": "black"},
    {"name": "Коричневый", "id": "brown"},
    {"name": "Оранжевый", "id": "orange"},
    {"name": "Желтый", "id": "yellow"},
    {"name": "Зеленый", "id": "green"},
    {"name": "Синий", "id": "blue"},
    {"name": "Фиолетовый", "id": "violet"},
    {"name": "Красный", "id": "red"},
  ];

  const [currentProject, setCurrentProject] = React.useState({});
  const [name, setName] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [color, setColor] = React.useState("");
  const [isValidDate, setIsValidDate] = React.useState(false);

  React.useEffect(() => {
    if (editedId) {
      fetchProject();
    }
  }, []);

  React.useEffect(() => {
    if (date instanceof Date && !isNaN(date)) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  }, [date]);

  React.useEffect(() => {
    setName(currentProject.name);
    setColor(currentProject.color);
    if (currentProject.start) {
      setDate(new Date(currentProject.start));
    } else {
      setDate(null);
    }
  }, [currentProject]);

  const fetchProject = async () => {
    await axios
      .get(`/projects/${editedId}`)
      .then((response) => {
        setCurrentProject(response.data);
      })
      .catch((err) => console.log(err));
  };

  const beforeSubmit = () => {
    onSubmit({ name, date, color });
  };

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название проекта*"}
                component={CustomTextField}
                name="name"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: name,
                  onChange: ({ target }) => setName(target.value)
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <CustomDateInput
                placeholder={"Дата начала*"}
                input={{ value: date, onChange: (date) => setDate(date) }}
              />
            </div>
            <div className={classes.input_item}>
            <CustomSelect
                inputProps={{
                  items: colors,
                  activeItem: color,
                  update: setColor,
                  label: "Цвет",
                  placeholder: "Цвет",
                }}
              />
            </div>
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              type="submit"
              disableElevation
              disabled={!name || !isValidDate}
            >
              Сохранить
            </Button>
          </div>
        </form>
      )}
    />
  );
};
