import * as React from "react";

const SvgFolderIcon = (props) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 5.25h-7L8.75 3.5H3.5c-.962 0-1.741.787-1.741 1.75l-.009 10.5c0 .962.788 1.75 1.75 1.75h14c.962 0 1.75-.788 1.75-1.75V7c0-.963-.788-1.75-1.75-1.75Zm0 10.5h-14V7h14v8.75Z"
      fill="#5E6368"
    />
  </svg>
);

export default SvgFolderIcon;
