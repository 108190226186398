import axios from "axios";
import * as types from "../types";
import { fetchProject } from "./projectsActions";

export const fetchGroups = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/groups", { params: { project_id: id } })
      .then((response) => {
        dispatch({ type: types.FETCH_GROUPS, payload: response.data });
      })
      .then(() => {
        dispatch(fetchProject(id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createGroup = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/groups", data)
      .then((response) => {
        dispatch(fetchGroups(data.project_id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const updateGroup = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .patch(`/groups/${id}`, data)
      .then((response) => {
        dispatch(fetchGroups(data.project_id));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const deleteGroup = (id, projectId) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/groups/${id}`)
      .then((response) => {
        dispatch(fetchGroups(projectId));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const fetchGroup = (id, projectId) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/groups/${id}`)
      .then((response) => {
        dispatch(fetchGroups(projectId));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};
