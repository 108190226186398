const SvgUserIcon = (props) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 9C15.5 10.66 14.16 12 12.5 12C10.84 12 9.5 10.66 9.5 9C9.5 7.34 10.84 6 12.5 6C14.16 6 15.5 7.34 15.5 9Z"
      fill="#78909C"
    />
    <path
      d="M6.5 17.0004C6.5 15.0004 10.5 13.9004 12.5 13.9004C14.5 13.9004 18.5 15.0004 18.5 17.0004V18.0004H6.5V17.0004Z"
      fill="#78909C"
    />
    <path
      d="M15.5 9C15.5 10.66 14.16 12 12.5 12C10.84 12 9.5 10.66 9.5 9C9.5 7.34 10.84 6 12.5 6C14.16 6 15.5 7.34 15.5 9Z"
      stroke="#78909C"
    />
    <path
      d="M6.5 17.0004C6.5 15.0004 10.5 13.9004 12.5 13.9004C14.5 13.9004 18.5 15.0004 18.5 17.0004V18.0004H6.5V17.0004Z"
      stroke="#78909C"
    />
    <rect x="3.5" y="2.5" width="18" height="18" rx="2.5" stroke="#78909C" />
  </svg>
);

export default SvgUserIcon;
