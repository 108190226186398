import React from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { UnlinkIcon, UserIcon } from "../../UI/Svgs";
import { Popup } from "../../UI/Popup/Popup";
import { useHover } from "../../../utils/hooks/useHover";

import classes from "./TasksRow.module.scss";
import { Popover } from "../../UI/Popover/Popover";

export const InnerTask = ({
  id,
  name,
  removeLink,
  countUnlinkHovered,
  folderDepth,
  type,
  onEdit,
  employee,
  dynamic_end,
  dynamic_start,
  actual_end,
  handleShowLinkedTask
}) => {
  const [hoverUnlinkRef, isUnlinkHovered] = useHover(countUnlinkHovered);

  const [isMoreMenuClicked, setIsMoreMenuClicked] = React.useState(false);

  const [hoverNameRef, isNameHovered] = useHover();
  const [moreMenuRef, isMoreMenuHovered] = useHover();

  const employeeIconCoords = hoverNameRef.current?.getBoundingClientRect();
  const moreIconCoords = moreMenuRef.current?.getBoundingClientRect();

  return (
    <div
      className={classes.innerTask}
      style={{
        paddingLeft: folderDepth * 35 + 95 + "px"
      }}
    >
      <div className={classes.name}>
        <button
          onClick={() => removeLink(id)}
          ref={hoverUnlinkRef}
          className={`${isUnlinkHovered ? classes.hoveredRed : ""} ${
            classes.unlinkBtn
          }`}
        >
          <UnlinkIcon />
          {isUnlinkHovered && countUnlinkHovered.current < 3 && (
            <div className={classes.popup}>
              <Popup text="Удалить связь" arrow />
            </div>
          )}
        </button>
        {name}
      </div>
      <div className={classes.employee}>
        {employee?.name ? (
          <span ref={hoverNameRef}>
            <UserIcon />
          </span>
        ) : (
          "Нет исполнителя"
        )}
        {isNameHovered && employee?.name && (
          <div className={classes.popup}>
            <Popover
              text={employee.name}
              coords={{
                top: employeeIconCoords.top,
                left: employeeIconCoords.left
              }}
              addedStyle={{
                transform: `translate(calc(-50% + 12px), calc(-100% - 23px))`
              }}
              arrow
            />
          </div>
        )}
      </div>
      <div className={classes.gray}>
        {dynamic_start ? new Date(dynamic_start).toLocaleDateString("ru-RU") : ""}
      </div>
      <div className={classes.gray}>
        {dynamic_end ? new Date(dynamic_end).toLocaleDateString("ru-RU") : ""}
      </div>
      <div className={classes.gray}>
        {actual_end ? new Date(actual_end).toLocaleDateString("ru-RU") : ""}
      </div>
      <div className={classes.more} ref={moreMenuRef}>
        <MoreHorizIcon
          onClick={() => setIsMoreMenuClicked(!isMoreMenuClicked)}
        />
        <div className={classes.popupMenu}>
          {isMoreMenuClicked && (
            <Popup>
              <div className={classes.menuWrapper}>
                <div className={classes.menuItem} onClick={() => onEdit(id)}>
                  О задаче
                </div>
                <div
                  className={classes.menuItem}
                  onClick={() => handleShowLinkedTask(id)}
                >
                  Показать задачу
                </div>
                <div
                  className={classes.menuItem}
                  onClick={() => removeLink(id)}
                >
                  Удалить связь
                </div>
              </div>
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
};
