import * as React from "react";

const SvgAddTask = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 1.75H5.25C4.2875 1.75 3.50875 2.5375 3.50875 3.5L3.5 17.5C3.5 18.4625 4.27875 19.25 5.24125 19.25H15.75C16.7125 19.25 17.5 18.4625 17.5 17.5V7L12.25 1.75ZM9.5725 15.75L6.475 12.6525L7.70875 11.4187L9.56375 13.2738L13.2738 9.56375L14.5075 10.7975L9.5725 15.75ZM11.375 7.875V3.0625L16.1875 7.875H11.375Z"
      fill="#5E6368"
    />
  </svg>
);

export default SvgAddTask;
