import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import store from "./store";
import $api from "./http";

import { App } from "./components/App/App";

import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Helmet>
          <title>Чат-бот КамаСтройИнвест</title>
          <meta name="title" content="Чат-бот КамаСтройИнвест" />
        </Helmet>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
