import * as React from "react";

const SvgArrowDownPlus = (props) => (
  <svg
    width={10}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 6.884V.75H5.25v6.134H2.625l3.5 3.491 3.5-3.491H7ZM3.125 9h-1.25v1.875H0v1.25h1.875V14h1.25v-1.875H5v-1.25H3.125V9Z"
      fill="#000"
    />
  </svg>
);

export default SvgArrowDownPlus;
