import axios from "axios";
import {
  getWithExpiry,
  setWithExpiry
} from "../../utils/localStorageWithExpiry";
import * as types from "../types";

export const sendLoginData = (login, password) => {
  return async (dispatch) => {
    const body = { login, password };

    await axios
      .post("/auth/login", body)
      .then((response) => {
        const token = response.data.access_token;
        const username = response.data.username;

        setWithExpiry("access_token", token, 5 * 24 * 60 * 60 * 1000);
        setWithExpiry("username", username, 5 * 24 * 60 * 60 * 1000);

        dispatch({ type: types.SET_USER_DATA, token: token, username: username });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.LOGIN_ERROR, detail: "login error" });
      });
  };
};

export const authMe = () => {
  return async (dispatch) => {
    await axios
      .get("/auth/me")
      .then((response) => {
        const token = getWithExpiry("access_token");
        const username = getWithExpiry("username");
        dispatch({ type: types.SET_USER_DATA, token: token, username: username });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.AUTH_ME_ERROR });
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    dispatch({ type: types.LOGOUT });
  };
};
