import * as React from "react";

const SvgArrowDown = (props) => (
  <svg
    width={8}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 6.884V.75H3.25v6.134H.625l3.5 3.491 3.5-3.491H5Z" fill="#000" />
  </svg>
);

export default SvgArrowDown;
