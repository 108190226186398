import React from "react";

import classes from "./Popup.module.scss";

export const Popup = ({ text, children, arrow = false }) => {
  return (
    <div className={`${classes.popup} ${!arrow && classes.not_arrow}`}>
      {text || children}
      {arrow && <div className={classes.triangle}></div>}
    </div>
  );
};
