import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import CustomTextField from "../UI/CustomTextField";
import { CustomDateInput } from "../UI/CustomDatePicker";
import { CustomSelect } from "../UI/CustomSelect";
import { fetchUsers } from "../../store/actions/usersActions";

import classes from "./TasksForm.module.scss";

export const TasksForm = ({ onSubmit, onCancel, editedId }) => {
  const dispatch = useDispatch();
  const { users } = useSelector(({ employees }) => employees);
  const statuses = [
    {"name": "Не начата", "id": "not_started"},
    {"name": "В работе", "id": "started"},
    {"name": "Завершена", "id": "completed"}
  ];

  const [currentTask, setCurrentTask] = React.useState({});
  const [form, setForm] = React.useState({});
  const [date, setDate] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [isValidDate, setIsValidDate] = React.useState(true);

  React.useEffect(() => {
    if (editedId) {
      fetchTask();
    }
  }, []);

  React.useEffect(() => {
    if ((date instanceof Date && !isNaN(date)) || !date) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  }, [date]);

  React.useEffect(() => {
    setForm(currentTask);
    setUser(currentTask.employee?.id);
    setStatus(currentTask.status);

    if (currentTask.start) {
      setDate(new Date(currentTask.start));
    } else {
      setDate(null);
    }
  }, [currentTask]);

  const fetchTask = async () => {
    await axios
      .get(`/tasks/${editedId}`)
      .then((response) => {
        setCurrentTask(response.data);
      })
      .catch((err) => console.log(err));
  };

  const beforeSubmit = (values) => {
    onSubmit({
      ...values,
      duration: +values.duration ?? currentTask.duration,
      description: values.description || currentTask.description,
      unique_code: values.unique_code || currentTask.unique_code,
      milestone_uid: values.milestone_uid,
      date,
      employee_id: user,
      status: status
    });
  };

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return (
    <Form
      onSubmit={beforeSubmit}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.inputs_wrapper}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название задачи*"}
                component={CustomTextField}
                name="name"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.name,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <CustomSelect
                inputProps={{
                  items: users,
                  activeItem: user,
                  update: setUser,
                  label: "Исполнитель",
                  placeholder: "Исполнитель",
                  emptyVariant: "Нет исполнителей для связи"
                }}
              />
            </div>
            <div className={classes.input_item}>
              <Field
                placeholder={"Уникальный код"}
                component={CustomTextField}
                name="unique_code"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.unique_code,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.input_item}>
              <Field
                placeholder={"GUID вехи"}
                component={CustomTextField}
                name="milestone_uid"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.milestone_uid,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
              />
            </div>
            <div className={classes.inputs_inner}>
              <div className={classes.input_item}>
                <Field
                  placeholder={"Количество дней*"}
                  component={CustomTextField}
                  type="number"
                  name="duration"
                  variant="outlined"
                  classes={{
                    root: classes.input
                  }}
                  inputProps={{
                    value: form.duration,
                    onChange: ({ target }) =>
                      setForm({ ...form, [target.name]: target.value })
                  }}
                  fullWidth
                />
              </div>
              <div className={classes.input_item}>
                <CustomDateInput
                  placeholder={"Дата начала"}
                  input={{ value: date, onChange: (date) => setDate(date) }}
                />
              </div>
            </div>

            <div className={classes.input_item}>
              <Field
                placeholder={"Описание"}
                component={CustomTextField}
                name="description"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: form.description,
                  onChange: ({ target }) =>
                    setForm({ ...form, [target.name]: target.value })
                }}
                fullWidth
                multiline
              />
            </div>
            {editedId?
              <CustomSelect
                inputProps={{
                  items: statuses,
                  activeItem: status,
                  update: setStatus,
                  label: "Статус",
                  placeholder: "Статус",
                }}
              />
              : null
            }
          </div>

          <div className={classes.button_wrapper}>
            <Button
              onClick={onCancel}
              classes={{
                root: classes.cancel_btn
              }}
              variant="contained"
              disableElevation
            >
              Отменить
            </Button>
            <Button
              classes={{
                root: classes.submit_btn
              }}
              variant="contained"
              type="submit"
              disableElevation
              disabled={
                (!form.duration && form.duration !== 0) ||
                !form.name ||
                !isValidDate
              }
            >
              Сохранить
            </Button>
          </div>
        </form>
      )}
    />
  );
};
