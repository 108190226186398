import React from "react";
import { Link } from "react-router-dom";
import { TableCell, TableRow, Button } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { DeleteIcon } from "../../UI/Svgs";

import classes from "./ContractorsRow.module.scss";

export const ContractorsRow = ({ row, index, onEdit, onDelete }) => {
  const handleEdit = (e) => {
    e.preventDefault();
    onEdit(row.id);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete(row.id);
  };

  return (
    <>
      <TableRow className={classes.projectsRow}>
        <TableCell className={classes.gray} width={120}>
          {index + 1}
        </TableCell>
        <TableCell width={400}>
          <div className={classes.nameInner}>
            <span className={classes.name}>{row.title}</span>
          </div>
        </TableCell>
        <TableCell width={300}>
          <div className={classes.nameInner}>
            <span className={classes.name}>{row.project.name}</span>
          </div>
        </TableCell>
        <TableCell className={classes.gray} width={300}>
          {new Date(row.work_start_date).toLocaleDateString("ru-RU")}
        </TableCell>
        <TableCell width={160}>
          <div className={classes.actions}>
            <Button
              onClick={handleEdit}
              classes={{
                root: classes.btn
              }}
            >
              <CreateIcon />
            </Button>
            <Button
              onClick={handleDelete}
              classes={{
                root: classes.btn
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
