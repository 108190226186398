import * as types from "../types";

const initialState = {
  tasks: null
};

export function tasksReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_TASKS:
      return {
        ...state,
        tasks: action.payload
      };

    default:
      return state;
  }
}
