import axios from "axios";
import * as types from "../types";

export const fetchUsers = () => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/employees")
      .then((response) => {
        dispatch({ type: types.FETCH_USERS, payload: response.data });
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const inviteEmpoyee = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/employees", { ...data })
      .then((response) => {
        dispatch(fetchUsers());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const updateEmployee = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .patch(`/employees/${id}`, data)
      .then((response) => {
        dispatch(fetchUsers());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const changeActiveStatus = (id, value) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    if (value) {
      await axios
        .patch(`/employees/${id}/block`)
        .then((response) => {
          dispatch(fetchUsers());
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
    } else {
      await axios
        .patch(`/employees/${id}/unblock`)
        .then((response) => {
          dispatch(fetchUsers());
        })
        .catch((err) => console.log(err))
        .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
    }
  };
};

export const resendInvitationAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post(`/employees/${id}`)
      .then((response) => {
        dispatch(fetchUsers());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};
