import React from "react";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import { required } from "../../utils/validators";
import { ModalWindow } from "../UI/ModalWindow/ModalWindow";
import { CustomSelect } from "../UI/CustomSelect";

import classes from "./ImportTasksModal.module.scss";

export const ImportTasksModal = ({
  openImportModal,
  handleCloseImportModal,
  onImport,
  projects
}) => {
  const [project, setProject] = React.useState(null);

  return (
    <ModalWindow
      isOpen={openImportModal}
      handleClose={handleCloseImportModal}
      title={"Импортировать задачи"}
    >
      <p className={classes.block_info}>
        Будьте внимательны, все ранее созданные в проекте задачи будут удалены
      </p>
      <Form
        onSubmit={onImport}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название проекта"}
                component={CustomSelect}
                name="title"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  activeItem: project,
                  items: projects,
                  update: setProject,
                  label: "Название проекта",
                  placeholder: "Название проекта",
                  emptyVariant: "Нет проектов для импорта"
                }}
                validate={required}
                fullWidth
              />
            </div>
            <div className={classes.button_wrapper}>
              <Button
                classes={{
                  root: classes.cancel_btn
                }}
                onClick={handleCloseImportModal}
                variant="contained"
                type="submit"
                disableElevation
              >
                Отменить
              </Button>
              <Button
                classes={{
                  root: classes.submit_btn
                }}
                onClick={() => onImport(project)}
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
              >
                Добавить
              </Button>
            </div>
          </form>
        )}
      />
    </ModalWindow>
  );
};
