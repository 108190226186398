import React from "react";
import { Button } from "@material-ui/core";
import { Field, Form } from "react-final-form";

import { required } from "../../utils/validators";
import { ModalWindow } from "../UI/ModalWindow/ModalWindow";
import CustomTextField from "../UI/CustomTextField";

import classes from "./CreateGroupModal.module.scss";
import axios from "axios";

export const CreateGroupModal = ({
  openModal,
  handleCloseModal,
  onSubmit,
  editedId,
  supergroup
}) => {
  const [currentGroup, setCurrentGroup] = React.useState({});
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    console.log(editedId);
    if (editedId) {
      fetchGroup();
    }
  }, []);

  React.useEffect(() => {
    setName(currentGroup.name);
  }, [currentGroup]);

  const fetchGroup = async () => {
    await axios
      .get(`/groups/${editedId}`)
      .then((response) => {
        setCurrentGroup(response.data);
      })
      .catch((err) => console.log(err));
  };

  const beforeSubmit = ({ name }) => {
    onSubmit({ name, supergroup });
  };

  return (
    <ModalWindow
      isOpen={openModal}
      handleClose={handleCloseModal}
      title={editedId ? "Редактировать группу" : "Создать группу"}
    >
      <Form
        onSubmit={beforeSubmit}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.input_item}>
              <Field
                placeholder={"Название группы"}
                component={CustomTextField}
                name="name"
                variant="outlined"
                classes={{
                  root: classes.input
                }}
                inputProps={{
                  value: name,
                  onChange: ({ target }) => setName(target.value)
                }}
                validate={required}
                fullWidth
              />
            </div>
            <div className={classes.button_wrapper}>
              <Button
                classes={{
                  root: classes.cancel_btn
                }}
                onClick={handleCloseModal}
                variant="contained"
                disableElevation
              >
                Отменить
              </Button>
              <Button
                classes={{
                  root: classes.submit_btn
                }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
                disableElevation
              >
                {editedId ? "Сохранить" : "Создать"}
              </Button>
            </div>
          </form>
        )}
      />
    </ModalWindow>
  );
};
