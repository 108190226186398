import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { usersReducer } from "./usersReducer";
import { projectsReducer } from "./projectsReducer";
import { tasksReducer } from "./tasksReducer";
import { groupsReducer } from "./groupsReducer";
import { contractorsReducer } from "./contractorsReducer";
import { constructionControlReducer } from "./constructionControlReducer";

export default combineReducers({
  login: loginReducer,
  employees: usersReducer,
  projects: projectsReducer,
  groups: groupsReducer,
  tasks: tasksReducer,
  contractors: contractorsReducer,
  cc_projects: constructionControlReducer
});
