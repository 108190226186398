import axios from "axios";
import * as types from "../types";

export const fetchCCProjects = () => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/construction_control")
      .then((response) => {
        dispatch({ type: types.FETCH_CCPROJECTS, payload: response.data });
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createCCProject = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .post("/construction_control", data)
      .then((response) => {
        dispatch(fetchCCProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const updateCCProject = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .patch(`/construction_control/${id}`, data)
      .then((response) => {
        dispatch(fetchCCProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const fetchCCProject = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get(`/construction_control/${id}`)
      .then((response) => {
        dispatch({ type: types.FETCH_CCPROJECT, payload: response.data });
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const deleteCCProject = (id) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .delete(`/construction_control/${id}`)
      .then((response) => {
        dispatch(fetchCCProjects());
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const downloadConstructionControlReport = () => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get(`/construction_control/export`, {
        responseType: "arraybuffer"
      })
      .then((response) => {
        return response.data;
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `construction_control_report.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};